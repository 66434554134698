.App {
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

li::marker {
  content: "";
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// .Landing {
//   background-color: gray;
// }

// #homeBG {
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
// }




/*****{ Nav }********************************************************************************************************************************/

.medianavbar {
  display: none;
}
.navbar {
  display: flex;
    align-items: center;
    width: 100%;
    max-height: 3.5vh;
    // font-family: 'spooky';
   background: #c1c1c1;
    z-index: 999;
    overflow-x: hidden;
   position: fixed;
   top: 0;
   transition: 1s;
   overflow-y: hidden;
   border:2px solid #000000;
}

.Word-Mark {
width: 6.5%;
border: 2px solid black;
border:2px solid #000000;
}
.Word-Mark img{
  transform: scaleX(0.8);
  width: 100%;
  position: relative;
  left: .5%;
  top: 3px;
}
.middle-cont {
  display: flex;
  width: 76%;
  height: 3.5vh;
}
.middle-cont .left-bar {
  display: flex;
  flex-direction: column;
  width:63%;
  height: 3.5vh;
  justify-content: center;
  align-items: center;
}
.middle-cont .left-bar div {
  display: flex;
  flex-direction: column;
  width: 95%;
  border: .1px solid black;
  border: .6px solid #949494;
  margin: 0.35% 0;
}
.middle-cont .middle-bar {
  display: flex;
  flex-direction: row;
  width: 35%;
  justify-content: center;
  align-items: center;
}


.middle-cont .middle-bar h1 {
 font-size: 110%;
 line-height: 25%;
  justify-content: center;
  font-family: spooky;
  bottom: 7px;
  position: relative;
  padding: 0;
  margin: 0;
  margin-top: 5%;
}

.textColor {
  color: black;
}

.middle-cont .right-bar {
  display: flex;
  flex-direction: column;
  width: 42%;
  height: 3.5vh;
  justify-content: center;
  align-items: center;
}

.middle-cont .right-bar div {
  display: flex;
  flex-direction: column;
  width:95%;
  border: 1px solid black;
  border: .6px solid #949494;
  margin: 0.45% 0;
}
.countDown {
  width: 17%;
  border-left: 2px solid black;
  border-right: 2px solid black;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.countDown h1 {
  font-size: 140%;
  font-weight: 3px;
  font-family: spooky;
}
.countDown h1 span {
  color: #ffff54;
  font-family: spooky;
  // animation: flashColors 1s linear infinite;
}

@keyframes flashColors {
  0% {
    color: black;
  }
  50% {
    color: yellow;
  }
  100% {
    color: black;
  }
}
.nav-pepe {
  width: 4%;
  height: 5vh;
  display: flex;
  justify-content: center;
}
.nav-pepe img{
  height: 75%;
  margin: auto;
  position: relative;
  background-color: rgba(0, 0, 255, 0);
  object-fit: cover;
}


.Wallet-Connect {
  background: black;
  border-radius: 16px;
  margin-right: 5%;
}

.Wallet-Connect button {
  color: white;
  filter: blur(10);
  padding-top: 0%;
  width: 150%;
  height: 30px;
  font-size: 20px;
  letter-spacing: 3px;
  background: black;
  border-radius: 0px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.3px);
  -webkit-backdrop-filter: blur(6.3px);
  border: 1px solid rgba(0, 0, 0, 0.97);
  transition: .2s;
  cursor: pointer;
  border: 2px solid rgba(255, 0, 0, 0);
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  border-radius: 5px;
}

.Wallet-Connect button:hover {
  color: rgb(255, 255, 255);
  background: rgba(0, 0, 0, 0.878);
}


.hambruger {
  display: none;
}

// @media (max-width: 1800px) 
// {
//   .Nav-Links {
//     margin-left: 45%;
//     width: 20%;
//   }
// }

// @media (max-width: 1700px) 
// {
//   .Nav-Links {
//     margin-left: 40%;
//     width: 20%;
//   }
// }

// @media (max-width: 1500px) 
// {
//   .Nav-Links {
//     margin-left: 40%;
//     width: 25%;
//   }
// }

.Landing {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh; // Adjust this value if you need the height to be different
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#landingBG {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  object-fit: cover;
}

.mintSection {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: white;
  align-items: center;
  border: 5px solid rgba(0, 0, 0, 0);
  margin-left: 0%;
  border-radius: 0px;
  height: 440px;
  width: 25%;
  bottom: 0%;
  position: relative;
}

.mintSection p {
  font-size: 150%;
  color: white;
  margin-top: 6%;
  font-family: spooky;
  letter-spacing: 5px;
}

.mintSection img {
  height: 200px;
  margin-top: 2.5%;
}

.mintingIncrements {
  margin-top: 5%;
  margin-bottom: 1%;
  width: 280px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mintingIncrements button {
  height: 40px;
  width: 40px;
  font-size: 25px;
  border-radius: 5px;
  border: 2px solid rgb(0, 0, 0);
  color: white;
  background: #f94700e1;
  cursor: pointer;
  transition: 0.2s;

}

.mintingIncrements button:hover {
  background: rgb(0, 0, 0);
}

.mintingIncrements input {
  height: 33px;
  width: 175px;
  color: white;
  border: 2px solid rgb(0, 0, 0);
  background: black;
  text-align: center;
  font-size: 125%;
  border-radius: 5px;
}

#mintbtn {
  margin-bottom: 5%;
  height: 50px;
  width: 280px;
  border: 2px solid rgba(0, 0, 0, 0.553);
  background: #f94700e1;
  color: white;
  font-size: 22.5px;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 5px;
}

#mintbtn:hover {
  background: rgb(0, 0, 0);
}

.info {
  position: relative;
  top: 20%;
  right: 35%;
  text-align: left;
}
.info h1{
  text-align: left;
  font-family: spooky;
  font-size: 300%;
  letter-spacing: 5px;
  color: #ebe6e4;
}
.info h2{
  text-align: left;
  font-family: spooky;
  font-size: 100%;
  line-height: 250%;
  letter-spacing: 5px;
  color: whitesmoke;
}
.info h2 span{
  text-align: left;
  font-family: spooky;
  font-size: 100%;
  line-height: 350%;
  letter-spacing: 5px;
  color: #f94700;
}

// allowlist 
.allowList {
  padding: 5px;
  width: 25%;
  margin-left: 0%;
  background: #acacac;
  border: 2.5px solid #000000;
  box-shadow: 1px 1px 5px;
  margin-bottom: 12.5px;
  transition: .2s;
  position: relative;
}
.allowList.active {
  height: 17.5px;
}

.allowList.deactive {
  display: none;
}

.allowList.active .mid1 {
}

.windowOptions {
  height: 17px;
  background: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  //border: 2.5px solid #939393;
}

.windowBtns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 57.5px;
}

.windowOptions button {
  border: 2px solid #8b8b8b;
  border-radius: 2.5px;
  background: #c0c0c0;
  height: 100%;
  padding: 0;
  position: relative;
}

.windowOptions button:hover {
  background: #d1d1d1;
}

.windowOptions button:active {
  background: #b6b6b6;
}

.windowOptions button:nth-child(1) {
  margin-left: 0%;
  width: 17px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.windowOptions button:nth-child(2) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.x {
  height: 1.5px;
  width: 12px;
  background: #878787;
  margin-left: 0%;
  //position: absolute;
}

.x:nth-child(1) {
  transform: translateY(6px) rotate(45deg);
  //transform:  translateX(-.75px) translateY(1.5px) rotate(45deg);
  //transform: translateY(4.25px)  rotate(45deg);
}

.x:nth-child(2) {
  transform: translateY(-6px) rotate(-45deg);
  //transform: translateX(-.75px) translateY(-.5px) rotate(-45deg);
  //transform: translateY(-4.25px) rotate(-45deg);
}

.windowOptions button:nth-child(2) {
  margin-left: 2.5%;
  width: 37.5px;
}

.drop {
  height: 1.5px;
  width: 9px;
  background: #878787;
  //margin-left: 18%;
  transition: .2s;
}

.drop:nth-child(1) {
  transform: translateX(1px) rotate(-45deg);
}

.drop:nth-child(2) {
  transform: translateX(-1px) rotate(45deg);
}

.dropDown.active .drop:nth-child(1) {
  transform: translateX(1.5px) rotate(45deg);
}

.dropDown.active .drop:nth-child(2) {
  transform: translateX(-1.25px) rotate(-45deg);
}

.lines {
  width: 60%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.line {
  height: 1.5px;
  width: 100%;
  background: #818181;
}

.windowOptions h2 {
  font-weight: 1000;
  color: #202020;
  font-size: 16px;
  margin-right: 0;
  padding: 0;
  font-family: spooky;
}

#SideWL1 {
  font-size: 13px;
  margin-left: 2%;
  margin-right: 2%}
#SideWL2 {
  font-size: 13px;
  margin-left: 2%;
    margin-right: 2%;
}

.inputSec {
  border: none;
  margin-top: 5px;
  height: 45vh;
  background: #c0c0c0;
  overflow: hidden;
  border: 2.5px solid #939393;

}

.inputSec.active {
  border: none;
  height: 0;
  margin-top: 0px;

}

.inputSec img {
  width: 100%;
  height: 100%;
}

.inputSec.active img {
  width: 0%;
  height: 0%;
}

.allowFooter {
  margin-bottom: 0% !important;
  border-top: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.allowFooter.active {
  height: 0%;
  margin-top: 0px;

}

.footerTitle {
  height: 60%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.footerTitle h2 {
  font-family: spooky;
  font-size: 60px;
  text-align: center;
  margin: 0;
  font-weight: 500;
  width: 100%;
  line-height: 90%;
}

.allowFooter.active .footerTitle{
  display: none;
}

.ftContent {
  height: 100%;
}

.allowInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  border-radius: 3.5px;
  overflow: hidden;
  height: 30px;
  width: 100%;
  overflow: hidden;
}

.allowInput input {
  background: #000000;
  width: 75%;
  height: 100%;
  border: #2c2c2c;
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
  font-family: spooky;
  text-align: right;
  font-size: 100%;
  transition: .2s;
}

.allowInput.outlineGlow input {
  box-shadow: inset 0px 0px 3px 3px #ffff54;
}

.allowInput input::placeholder {
  color: #ffffff;
  opacity: 1;
}

.allowInput button {
  background: #ffff54;
  height: 100%;
  width: 20%;
  font-family: spooky;
  font-size: 125%;
  font-weight: 1000;
  line-height: 60%;
  padding: 2px;
}

// mint section 
.demboyz {
  padding: 5px;
  width: 29%;
  margin-left: 0%;
  background: #acacac;
  border: 2.5px solid #000000;
  box-shadow: 1px 1px 5px;
  margin-bottom: 0px;
  bottom: 14.5%;
  transition: .2s;
  position: absolute;
   margin-bottom: 0%;
}

.demboyz.active {
  height: 17.5px;
  top: 40%;
}

.demboyz.deactive {
  display: none; 
}

.demboyz.active .mid1 {
}

.demboyzSec {
  border: none;
  margin-top: 5px;
  height: 55vh;
  background: #6a8171;
  overflow: hidden;
  border: 2.5px solid #565656;
}

.demboyzSec.active {
  border: none;
  height: 0;
  margin-top: 0px;

}

.demboyzSec img {
  width: 100%;
  height: 100%;
}

.demboyzSec.active img {
  width: 0%;
  height: 0%;
}

.demboyzFooter {
  margin-top: 7.5px;
  margin-bottom: 5px;
  border-top: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.demboyzFooter.active {
  height: 0%;
  margin-top: 0px;

}

.demboyzInput {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  border-radius: 3.5px;
  overflow: hidden;
  width: 100%;
  overflow: hidden;
}

.demboyzInput button {
  background: #ffff54;
  height: 30px;
  width: 100%;
  font-family: spooky;
  font-size: 125%;
  font-weight: 1000;
  line-height: 60%;
  padding: 2px;
}

.amountSelect {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-bottom: 7.5px;
}

.amountSelect.deactive {
  display: none;
}

.amountSelect button::before {
  content: "";
  display: block;
  padding-top: 50%;
}

.amountSelect button{
  background: #d4d0c8;
  color: black;
  font-family: spooky;
  font-size: 31px;
  font-weight: 100;
  height: auto;
  width: 100%;
  box-shadow: none;
  border: 1px solid black;
  border-left: .5px solid black;
  border-right: .5px solid rgb(31, 29, 29);
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.506);
  line-height: 0;
  cursor: pointer;
  transition: .2s;
}

.amountSelect button::after {
  content: "";
  display: block;
  padding-bottom: 50%;
}

.amountSelect button:nth-child(1){
  border-left: 2px solid black;
}

.amountSelect button:nth-child(10){
  border-right: 2px solid black;
}

.amountSelect button.clicked {
  background: #9b9a97;
}

.mintText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  position: relative;
}

.mintText.deactive {
  display: none;
}

.mintText p {
  font-family: spooky;
  font-weight: 1000;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
  margin-top: 5px;
}

.mintText h2 {
  font-family: spooky;
  font-weight: 100;
  margin: 0;
  padding: 0;
  font-size: 28px;
  margin-left: 5px;
  padding-bottom: 0;
  line-height: 90%;
  margin-top: 10px;
}

.mintPrice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 10px;
}

.minted {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 0px;
}

#dbmintbtn {
  cursor: pointer;
}

#dbmintbtn.deactive {
  display: none;
}

#walletConnect {
  cursor: pointer;
}

#walletConnect.deactive {
  display: none;
}

// wallet checker 
.walletCheck {
  padding: 5px;
  width: 30%;
  margin-left: 0%;
  background: #acacac;
  border: 2.5px solid #000000;
  box-shadow: 1px 1px 5px;
  margin-bottom: 0px;
  bottom: 14.5%;
  transition: .2s;
  position: absolute;
}
.walletCheck.active {
  height: 17.5px;
  top: 40%;
}

.walletCheck.deactive {
  display: none;
}

.walletCheck.active .mid1 {

}

.walletCheckSec {
  border: none;
  margin-top: 5px;
  height: 62vh;
  background: #6a8171;
  overflow: hidden;
  border: 2.5px solid #565656;

}

.walletCheckSec.active {
  border: none;
  height: 0;
  margin-top: 0px;

}

.walletCheckSec img {
  width: 100%;
  height: 100%;
}

.walletCheckSec.active img {
  width: 0%;
  height: 0%;
}

.walletCheckFooter {
  margin-top: 7.5px;
  margin-bottom: 5px;
  border-top: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.walletCheckFooter.active {
  height: 0%;
  margin-top: 0px;

}

.walletCheckInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  border-radius: 3.5px;
  overflow: hidden;
  height: 30px;
  width: 100%;
  overflow: hidden;
}

.walletCheckInput input {
  background: #000000;
  width: 75%;
  height: 100%;
  border: #2c2c2c;
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
  font-family: spooky;
  text-align: right;
  font-size: 100%;
}

.walletCheckInput input::placeholder {
  color: #ffffff;
  opacity: 1;
}

.walletCheckInput button {
  background: #ffff54;
  height: 100%;
  width: 100%;
  font-family: spooky;
  font-size: 125%;
  font-weight: 1000;
  line-height: 60%;
  padding: 2px;
}

// wallet flash

.walletCheckError {
  width: 110%;
  margin-top: 0%;
  margin-left: 0%;
  padding: 5px;
  background: #acacac;
  border: 2.5px solid #000000;
  box-shadow: 1px 1px 5px;
  position: absolute;
  left: -6.75%;
  top: 0%;
  z-index: 998;
}

.walletCheckEerror.active {
  height: 17.5px;

}

.walletCheckError.deactive {
  display: none;
}

.walletCheckError.active .rSide1 {
}

.rx:nth-child(1) {
  transform: translateX(.25px) translateY(6.25px) rotate(45deg);
}

.rx:nth-child(2) {
  transform: translateX(.25px) translateY(-6.25px) rotate(-45deg);
}

.walletCheckErrordropDown.active .drop:nth-child(1) {
  transform: translateX(1.5px) rotate(45deg);
}

.walletCheckErrordropDown.active .drop:nth-child(2) {
  transform: translateX(-1.25px) rotate(-45deg);
}

#walletCheckErrorTitle {
  font-weight: 0;
}

.walletCheckErrorSec {
  margin-top: 5px;
  background: #e8473e;
  border: none;
  overflow: hidden;
  border: 2.5px solid #939393;
  padding-top: 00px;
  padding-bottom: 0px;
  transition: none;
}

.walletCheckErrorSec.active {
  height: 0%;
  border: none;
  padding: 0;
}

.walletCheckErrorSec h2 {
  color: #ffff54;
  font-size: 43px;
  letter-spacing: 5px;
  padding: 0;
  margin: 0;
  line-height: 140%;
  font-family: spooky;

}

.walletCheckErrorSec.flash {
  background: #ffff54;
}

.walletCheckErrorSec.flash h2 {
  color: #e8473e;
}

.walletCheckErrorSec.active h2 {
  font-size: 0px;
  margin-left: 2.5%;
  margin: 0;
  font-family: 100;
}

.errorGap {
  height: 20px;
}

.walletCheckErrorImgSec {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-left: 5%;
  margin-top: 25px;
  margin-bottom: 25px;
}

.walletCheckErrorImgCont  {
  height: 65px;
  width: 65px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

}

.walletCheckErrorImgSec:nth-child(1) {
  margin-top: 35px;
  margin-bottom: 40px;
}

.walletCheckErrorImgCont img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.walletCheckErrorExp {
  position: absolute;
  font-size: 60px;
  font-family: spooky;
  padding: 0;
  margin: 0;
  letter-spacing: -3px;
  margin-top: 5px;
}

// legal

.legal {
  width: 25%;
  margin-top: 0%;
  margin-left: 0%;
  padding: 5px;
  background: #acacac;
  border: 2.5px solid #000000;
  box-shadow: 1px 1px 5px;
  margin-bottom: 12.5px;
  position: absolute;
  left: 50px;
  top: 150px;
}

.legal.active {
  height: 17.5px;

}
.rline {
  width: 60%;
}

.legal.deactive {
  display: none;
}

.legal.active .rSide1 {
}

.lx:nth-child(1) {
  transform: translateX(.5px) translateY(6px) rotate(45deg);
}

.lx:nth-child(2) {
  transform: translateX(.5px) translateY(-6px) rotate(-45deg);
}

.legaldropDown.active .drop:nth-child(1) {
  transform: translateX(1px) rotate(45deg);
}

.legaldropDown.active .drop:nth-child(2) {
  transform: translateX(-1.5px) rotate(-45deg);
}

.legalSec {
  margin-top: 5px;
  height: 50vh;
  background: #c0c0c0;
  border: none;
  overflow: hidden;
  overflow-y: scroll;
  padding: 25px;
  border: 2.5px solid #939393;
}

.legalSec::-webkit-scrollbar {
  width: 10px;
  
}

.legalSec::-webkit-scrollbar-track {
  background: none;
}

.legalSec::-webkit-scrollbar-thumb {
  background: #888;
  //border-radius: 5px;
}

.legalSec::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.legalSec.active {
  height: 0;
  padding: 0;
  border: none;

}

#initDesclaimer {
  font-weight: bold;
}

.legalSec h1 {
  font-size: 30px;
  padding: 0;
  margin: 0;
  line-height: 100%;
  font-family: spooky;
  margin-left: 2.5%;
  margin-bottom: 7.5%;
}


.legalSec h2 {
  font-size: 20px;
  padding: 0;
  margin: 0;
  line-height: 100%;
  font-family: spooky;
  margin-top: 15%;
  margin-bottom: 5%;
}
.legalSec p {
  font-size: 15px;
  padding: 0;
  margin: 0;
  font-family: spooky;
  text-align: left;
}

.legalSec.active h1 {
  font-size: 0px;

}

.legalSec.active h2 {
  font-size: 0px;
  margin-left: 2.5%;

}

.legalSec.active p {
  font-size: 0px;
  font-weight: 500;
  text-align: left;
}

// ERROR

.error {
  width: 110%;
  margin-top: 0%;
  margin-left: 0%;
  padding: 5px;
  background: #acacac;
  border: 2.5px solid #000000;
  box-shadow: 1px 1px 5px;
  position: absolute;
  left: -6.75%;
  top: 20%;
  z-index: 998;
}

.error.active {
  height: 17.5px;

}

.error.deactive {
  display: none;
}

.error.active .rSide1 {
}

.rx:nth-child(1) {
  transform: translateX(.25px) translateY(6.25px) rotate(45deg);
}

.rx:nth-child(2) {
  transform: translateX(.25px) translateY(-6.25px) rotate(-45deg);
}

.errordropDown.active .drop:nth-child(1) {
  transform: translateX(1.5px) rotate(45deg);
}

.errordropDown.active .drop:nth-child(2) {
  transform: translateX(-1.25px) rotate(-45deg);
}

#errorTitle {
  color: #ffff54;
  font-weight: 0;
}

.errorSec {
  margin-top: 5px;
  background: #e8473e;
  border: none;
  overflow: hidden;
  border: 2.5px solid #939393;
  padding-top: 60px;
  padding-bottom: 65px;
  transition: none;
}

.errorSec.active {
  height: 0%;
  border: none;
  padding: 0;
}

.errorSec h2 {
  color: #ffff54;
  font-size: 43px;
  letter-spacing: 5px;
  padding: 0;
  margin: 0;
  line-height: 100%;
  font-family: spooky;

}

.errorSec.flash {
  background: #ffff54;
}

.errorSec.flash h2 {
  color: #e8473e;
}

.errorSec.active h2 {
  font-size: 0px;
  margin-left: 2.5%;
  margin: 0;
  font-family: 100;
}

.errorGap {
  height: 20px;
}

.errorImgSec {
  display: none;
}



// counter

.counter {
  width: 20%;
  margin-top: 0%;
  margin-left: 0%;
  padding: 5px;
  background: #acacac;
  border: 2.5px solid #000000;
  box-shadow: 1px 1px 5px;
  position: absolute;
  right: 10%;
  bottom: 14.5%;
  
}

.counter.active {
  height: 17.5px;

}

.counter.deactive {
  display: none;
}

.counter.active .rSide1 {
}

.rx:nth-child(1) {
  transform: translateX(.25px) translateY(6.25px) rotate(45deg);
}

.rx:nth-child(2) {
  transform: translateX(.25px) translateY(-6.25px) rotate(-45deg);
}

.countdropDown.active .drop:nth-child(1) {
  transform: translateX(1.5px) rotate(45deg);
}

.countdropDown.active .drop:nth-child(2) {
  transform: translateX(-1.25px) rotate(-45deg);
}

.counterSec {
  margin-top: 5px;
  background: #c0c0c0;
  border: none;
  overflow: hidden;
  border: 2.5px solid #939393;
}

.counterSec.active {
  height: 0%;
  border: none;
  padding: 0;
}

.counterSec h2 {
  color: #000000;
  font-size: 55px;
  padding: 0;
  margin: 0;
  line-height: 100%;
  font-family: spooky;

}
.counterSec h3 {
  color:  #ff0000;
  font-size: 35px;
  padding: 0;
  margin: 0;
  font-family: spooky;

}

.counterSec.active h2 {
  font-size: 75px;
  letter-spacing: 18px;
  margin-left: 2.5%;
  margin: 0;
}

.counterSec.active h3 {
  font-size: 0px;
}

// desktopicon 
.desktopIcon {
  width: 70px;
  height: 50%;
  position: absolute;
  right: 5%;
  top: 12.5%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.iconSet1 {
  height: 100%;
}
.iconSet2 {
  height: 100%;
}
.icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.icon a {
  text-decoration: none;
}

.desktopIcon img {
  height: 62.5px;
  width: 62.5px;
  margin: 0;
}

.desktopIcon p {
  height: 20px;
  padding: 0px;
  padding-left: 6px;
  padding-right: 6px;
  background: #0b0b0b71;
  color: white;
  font-size: 12.5px;
  border-radius: 7.5px;
  font-family: spooky;
  margin: 0;
  text-decoration: none;
}

// Milady AL 

.miladyAL {
  width: 25%;
  margin-top: 0%;
  margin-left: 0%;
  padding: 5px;
  background: #acacac;
  border: 2.5px solid #000000;
  box-shadow: 1px 1px 5px;
  margin-bottom: 12.5px;
}

.miladyAL.active {
  height: 17.5px;
}

.miladyAL.deactive {
  display: none;
}

.miladydropDown.active .drop:nth-child(1) {
  transform: translateX(1.5px) rotate(45deg);
}

.miladydropDown.active .drop:nth-child(2) {
  transform: translateX(-1.25px) rotate(-45deg);
}

.miladyALInputSec {
  background: #c0c0c0;
  border: none;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  border: 2px solid #949494;
  margin-top: 5px;
  padding: 10px;
}

.miladyALInputSec h2 {
  font-family: spooky;
  font-size: 60px;
  text-align: center;
  margin: 0;
  font-weight: 500;
  width: 100%;
  line-height: 90%;
}

.miladyALInputSec.active {
  height: 0;
  margin-top: 0px;
  border: none;
  display: none;
  padding: 0;
}

.miladyALInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  border-radius: 3.5px;
  overflow: hidden;
  height: 30px;
  overflow: hidden;
}

.miladyALInput.active {
  height: 0px;
  margin-top: 0px;
}

.miladyALInput input {
  background: #000000;
  width: 75%;
  height: 100%;
  border: #2c2c2c;
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
  font-family: spooky;
  text-align: right;
  font-size: 100%;
}

.miladyALInput input::placeholder {
  color: #ffffff;
  opacity: 1;
}

.miladyALInput button {
  background: #ffff54;
  height: 100%;
  width: 20%;
  font-family: spooky;
  font-size: 110%;
  line-height: 60%;
  padding: 2px;
  font-weight: 1000;
}

// PEPE AL 
.pepeAL {
  width: 25%;
  margin-top: 0%;
  margin-left: 0%;
  background: #acacac;
  border: 2.5px solid #000000;
  box-shadow: 1px 1px 5px;
  padding: 5px;
  margin-bottom: 15px;
}

.pepeAL.active {
  height: 17.5px;
}

.pepeAL.deactive {
  display: none;
}

.pepedropDown.active .drop:nth-child(1) {
  transform: translateX(1.5px) rotate(45deg);
}

.pepedropDown.active .drop:nth-child(2) {
  transform: translateX(-1.25px) rotate(-45deg);
}

.pepeALInputSec {
  margin-top: 5px;
  background: #c0c0c0;
  border: none;
  display: flex;
  justify-content: flex-start;
  border: 2px solid #949494;
  overflow: hidden;
  padding: 10px;
}

.pepeALInputSec h2 {
  font-family: spooky;
  font-size: 60px;
  text-align: center;
  margin: 0;
  font-weight: 500;
  width: 100%;
  line-height: 90%;
}

.pepeALInputSec.active {
  height: 0vh;
  margin-top: 0px;
  border: none;
  display: none;
}


.pepeALInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  border-radius: 3.5px;
  overflow: hidden;
  height: 30px;
  overflow: hidden;
}


.pepeALInput.active {
  height: 0px;
  margin-top: 0px;

}

.pepeALInput input {
  background: #000000;
  width: 75%;
  height: 100%;
  border: #2c2c2c;
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
  font-family: spooky;
  text-align: right;
  font-size: 100%;
}

.pepeALInput input::placeholder {
  color: #ffffff;
  opacity: 1;
}

.pepeALInput button {
  background: #ffff54;
  height: 100%;
  width: 20%;
  font-family: spooky;
  font-size: 110%;
  line-height: 60%;
  padding: 2px;
  font-weight: 1000;
}

#PEPESign {
  position: absolute;
  height: 315px;
  width: 315px;
  left: 7.5%;
  bottom: -100%;
  z-index: -1;
  transition: .2s;
}

#PEPESign.pop {
  bottom: 0%;
}

#mintgif
  {
    position: absolute;
  height: 225px;
  width: 225px;
  right: 12.5%;
  top: 3%;
  z-index: -1;
  transition: .2s;
  }
  #mintgif.pop {
    bottom: 0%;
  }

  #mintgif2 {
    display: none;
  }
    /******{ nav media }**********************************************************************/


.nav-active {
  transform: translateX(0%);
position: fixed;
}

.body {
  position: fixed;
}


.landing-footer {
  width: 100%;
  height: 30px;
  background-color: #d9d9d9;
  position: fixed;
  bottom: 0;
}
.landing-footer .left-box {
  width: 8%;
  height: 27px;
  border: 2px solid #d9d9d9;
  background-color: #181717;
  position: fixed;
  bottom: 0;
}
.landing-footer .right-box {
  width: 8%;
  height: 27px;
  border: 2px solid #181717;
  background-color: #d9d9d9;
  position: fixed;
  right: 0;
  bottom: 0;
}


// laptop 

@media (max-width: 1750px) {
  .footerTitle h2 {
    font-size: 50px;
  }
  .miladyALInputSec h2 {
    font-size: 50px;
  }
  .pepeALInputSec h2 {
    font-size: 50px;
  }
}

@media (max-width: 1550px) {

/*****{ Nav }********************************************************************************************************************************/

.navbar {
  display: flex;
    align-items: center;
    width: 100%;
    max-height: 3.5vh;
    // font-family: 'spooky';
   background: #c1c1c1;
    z-index: 100;
    overflow-x: hidden;
   position: fixed;
   top: 0;
   transition: 1s;
   overflow-y: hidden;
   border:2px solid #000000;
}

.Word-Mark {
width: 6.5%;
border: 2px solid black;
border:2px solid #000000;
}

.middle-cont {
  display: flex;
  width: 76%;
  height: 3.5vh;
}
.middle-cont .left-bar {
  display: flex;
  flex-direction: column;
  width:63%;
  height: 3.5vh;
  justify-content: center;
  align-items: center;
}
.middle-cont .left-bar div {
  display: flex;
  flex-direction: column;
  width: 95%;
  border: .1px solid black;
  border: .6px solid #949494;
  margin: 0.35% 0;
}
.middle-cont .middle-bar {
  display: flex;
  flex-direction: row;
  width: 35%;
  justify-content: center;
  align-items: center;
}


.middle-cont .middle-bar h1 {
 font-size: 110%;
 line-height: 25%;
  justify-content: center;
  font-family: spooky;
  bottom: 7px;
  position: relative;
  padding: 0;
  margin: 0;
  margin-top: 5%;
}



.middle-cont .right-bar {
  display: flex;
  flex-direction: column;
  width: 42%;
  height: 3.5vh;
  justify-content: center;
  align-items: center;
}

.middle-cont .right-bar div {
  display: flex;
  flex-direction: column;
  width:95%;
  border: 1px solid black;
  border: .6px solid #949494;
  margin: 0.45% 0;
}
.countDown {
  width: 17%;
  border-left: 2px solid black;
  border-right: 2px solid black;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.countDown h1 {
  font-size: 140%;
  font-weight: 3px;
  font-family: spooky;
}
.countDown h1 span {
  font-family: spooky;
}

.nav-pepe {
  width: 4%;
  height: 5vh;
  display: flex;
  justify-content: center;
}
.nav-pepe img{
  height: 100%;
  height: 3.5vh;
  margin: auto;
  position: relative;
  background-color: rgba(0, 0, 255, 0);
}
.nav-pepe img {
 width: 60%;
}

.Wallet-Connect {
  background: black;
  border-radius: 16px;
  margin-right: 5%;
}

.Wallet-Connect button {
  color: white;
  filter: blur(10);
  padding-top: 0%;
  width: 150%;
  height: 30px;
  font-size: 20px;
  letter-spacing: 3px;
  background: black;
  border-radius: 0px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.3px);
  -webkit-backdrop-filter: blur(6.3px);
  border: 1px solid rgba(0, 0, 0, 0.97);
  transition: .2s;
  cursor: pointer;
  border: 2px solid rgba(255, 0, 0, 0);
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  border-radius: 5px;
}

.Wallet-Connect button:hover {
  color: rgb(255, 255, 255);
  background: rgba(0, 0, 0, 0.878);
}


.hambruger {
  display: none;
}

// @media (max-width: 1800px) 
// {
//   .Nav-Links {
//     margin-left: 45%;
//     width: 20%;
//   }
// }

// @media (max-width: 1700px) 
// {
//   .Nav-Links {
//     margin-left: 40%;
//     width: 20%;
//   }
// }

// @media (max-width: 1500px) 
// {
//   .Nav-Links {
//     margin-left: 40%;
//     width: 25%;
//   }
// }

.Landing {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh; // Adjust this value if you need the height to be different
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}



.mintSection {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: white;
  align-items: center;
  border: 5px solid rgba(0, 0, 0, 0);
  margin-left: 0%;
  border-radius: 0px;
  height: 440px;
  width: 25%;
  bottom: 0%;
  position: relative;
}

.mintSection p {
  font-size: 150%;
  color: white;
  margin-top: 6%;
  font-family: spooky;
  letter-spacing: 5px;
}

.mintSection img {
  height: 200px;
  margin-top: 2.5%;
}

.mintingIncrements {
  margin-top: 5%;
  margin-bottom: 1%;
  width: 280px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mintingIncrements button {
  height: 40px;
  width: 40px;
  font-size: 25px;
  border-radius: 5px;
  border: 2px solid rgb(0, 0, 0);
  color: white;
  background: #f94700e1;
  cursor: pointer;
  transition: 0.2s;

}

.mintingIncrements button:hover {
  background: rgb(0, 0, 0);
}

.mintingIncrements input {
  height: 33px;
  width: 175px;
  color: white;
  border: 2px solid rgb(0, 0, 0);
  background: black;
  text-align: center;
  font-size: 125%;
  border-radius: 5px;
}

#mintbtn {
  margin-bottom: 5%;
  height: 50px;
  width: 280px;
  border: 2px solid rgba(0, 0, 0, 0.553);
  background: #f94700e1;
  color: white;
  font-size: 22.5px;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 5px;
}

#mintbtn:hover {
  background: rgb(0, 0, 0);
}

.info {
  position: relative;
  top: 20%;
  right: 35%;
  text-align: left;
}
.info h1{
  text-align: left;
  font-family: spooky;
  font-size: 300%;
  letter-spacing: 5px;
  color: #ebe6e4;
}
.info h2{
  text-align: left;
  font-family: spooky;
  font-size: 100%;
  line-height: 250%;
  letter-spacing: 5px;
  color: whitesmoke;
}
.info h2 span{
  text-align: left;
  font-family: spooky;
  font-size: 100%;
  line-height: 350%;
  letter-spacing: 5px;
  color: #f94700;
}
// deleted window div and contents

/******{ nav media }**********************************************************************/


.nav-active {
  transform: translateX(0%);
position: fixed;
}

.body {
  position: fixed;
}


.landing-footer {
  width: 100%;
  height: 30px;
  background-color: #d9d9d9;
  position: fixed;
  bottom: 0;
}
.landing-footer .left-box {
  width: 8%;
  height: 27px;
  border: 2px solid #d9d9d9;
  background-color: #181717;
  position: fixed;
  bottom: 0;
}
.landing-footer .right-box {
  width: 8%;
  height: 27px;
  border: 2px solid #181717;
  background-color: #d9d9d9;
  position: fixed;
  right: 0;
  bottom: 0;
}

.windowOptions h2 {
  font-size: 14px;
}

#SideWL1 {
  font-size: 11px;
  margin-left: 0%;
  margin-right: 1%}
#SideWL2 {
  font-size: 11px;
  margin-left: 0%;
    margin-right: 1%;
}

.counterSec h2{
  font-size: 50px;
}

.counterSec h3{
  font-size: 30px;
}

.allowInput button {
  font-size: 100%;
}

.miladyALInput button {
  font-size: 90%;
}
.pepeALInput button {
  font-size: 90%;
}

.footerTitle h2 {
  font-size: 45px;
}
.miladyALInputSec h2 {
  font-size: 45px;
}
.pepeALInputSec h2 {
  font-size: 45px;
}
.legalSec h1 {
  font-size: 25px;
}

.errorSec h2 {
  font-size: 37.5px;
}
.errorGap {
  height: 17.5px;
}


}

@media (max-width: 1540px) {
  .counterSec h2 {
    font-size: 50px !important;
  }
  .counterSec h3 {
    font-size: 27.5px;
  }
}

@media (max-width: 1405px) {
  .footerTitle h2 {
    font-size: 40px;
  }
  .miladyALInputSec h2 {
    font-size: 40px;
  }
  .pepeALInputSec h2 {
    font-size: 40px;
  }
  .countDown h1{
    font-size: 130%;
  }
  .errorSec h2 {
    font-size: 35px;
  }
  .errorGap {
    font-size: 15px;
  }

  .walletCheckErrorSec h2 {
    font-size: 37px;
  }

  .walletCheckErrorImgCont  {
    height: 60px;
    width: 60px;
  }

  .walletCheckErrorExp {
    font-size: 55px;
  }
}

@media(max-width: 1370px) {
  .counterSec h2 {
    font-size: 50px;
  }
  .counterSec h3 {
    font-size: 27.5px;
  }
  .allowInput button {
    font-size: 90%;
  }
  .miladyALInput button {
    font-size: 80%;
  }
  .pepeALInput button {
    font-size: 80%;
  }
  .countDown h1{
    font-size: 110%;
  }
}
@media(max-width: 1300px) {
  .counterSec h2 {
    font-size: 45px;
  }
  .counterSec h3 {
    font-size: 25px;
  }
  .errorSec h2 {
    font-size: 32.5px;
  }
  .errorGap {
    height: 12.5px;
  }

  .walletCheckErrorSec h2 {
    font-size: 32px;
  }

  .walletCheckErrorImgCont  {
    height: 55px;
    width: 55px;
  }

  .walletCheckErrorExp {
    font-size: 50px;
  }
}

@media (max-width: 1260px) {
  .footerTitle h2 {
    font-size: 35px;
  }
  .miladyALInputSec h2 {
    font-size: 35px;
  }
  .pepeALInputSec h2 {
    font-size: 35px;
  }
}


@media(max-width: 1220px) {
  .counterSec h2 {
    letter-spacing: 0px;
    font-size: 45px;
  }
  .counterSec h3 {
    font-size: 25px;
  }
  .allowInput button {
    font-size: 80%;
  }
  .miladyALInput button {
    font-size: 70%;
  }
  .pepeALInput button {
    font-size: 70%;
  }
  .windowOptions h2 {
    font-size: 9px;
  }
  .rline {
    width: 60%;
  }
  #SideWL1 {
    font-size: 8px;
  }
  #SideWL2 {
    font-size: 8px;
  }
  .middle-cont .middle-bar h1 {
    font-size: 100%;
  }
  .errorSec h2 {
    font-size: 30px;
  }
  .errorGap {
    height: 10px;
  }
}

@media (max-width: 1160px) {
  .errorSec h2 {
    font-size: 27.5px;
  }
  .errorGap {
    height: 7.5px;
  }

  .walletCheckErrorSec h2 {
    font-size: 28px;
  }

  .walletCheckErrorImgCont  {
    height: 50px;
    width: 50px;
  }

  .walletCheckErrorExp {
    font-size: 45px;
  }
}

@media (max-width: 1120px) {
  .footerTitle h2 {
    font-size: 30px;
  }
  .miladyALInputSec h2 {
    font-size: 30px;
  }
  .pepeALInputSec h2 {
    font-size: 30px;
  }.errorSec h2 {
    font-size: 25px;
  }
  .errorGap {
    height: 5px;
  }
}

  @media(max-width: 1105px) {
    .counterSec h2 {
      letter-spacing: 0px;
      font-size: 40px;
    }
    .counterSec h3 {
      font-size: 20px;
    }
    .allowInput button {
      font-size: 70%;
    }
    .miladyALInput button {
      font-size: 60%;
    }
    .pepeALInput button {
      font-size: 60%;
    }
    .countDown h1 {
      font-size: 95%;
    }
    .middle-cont .middle-bar h1 {
      font-size: 90%;
    }
    .errorSec h2 {
      font-size: 22.5px;
    }
    .errorGap {
      height: 5px;
    }
}

@media (max-width: 990px) {
  .middle-cont .middle-bar h1 {
    font-size: 80%;
  }
}

// Mobile media 

@media(max-width: 950px) {
  
  .rline {
    width: 70%;
  }

  .navbar {
    display: none;
    max-height: 4.5vh;
  }
  .desktopIcon {
    display: none;
  }

  #PEPESign {
    display: none;
  }
  #mintgif {
    display: none;
  }
  #mintgif2
  {
    display: block;
    position: fixed;
  height: 225px;
  width: 225px;
  right: 22%;
  bottom: -100%;
  z-index: 98;
  transition: .2s;
  }
  #mintgif2.pop {
    bottom: 0%;
  }
  .hamburger {
      display:flex;
      flex-direction: column;
      height: 35px;
      justify-content: space-between;
      margin-right: 0.5%;
      position: reletive;
      width: 45px;
      left: 50%;
  }

  .bar {
      background: #fff;
      border-radius: 15px;
      box-shadow: 4px 4px 1px 1px #0b0b0b;
      height: 5px;
      transition: .2s;
      width: 100%;
      position: relative;
      right: -400%;
  }

  .hamburger.active .bar:first-child {
      rotate: 45deg;
      -webkit-transform: translateY(25.5px);
      transform: translateY(20.5px)
  }

  .hamburger.active .bar:nth-child(2) {
      opacity: 0
  }

  .hamburger.active .bar:nth-child(3) {
      rotate: -45deg;
      -webkit-transform: translateY(-20.5px);
      transform: translateY(-20.5px)
  }

  .Nav-Links {
      position: fixed;
      right: -100%;
      top: 14%;
      transition: .2s;
      width: 100%;
      width: 50%;
      display: none;
  }

  .Nav-Links.active {
      right: 2.5%
  }

  .Nav-Links li {
      margin-left: 10%
  }
  .walletCheck {
    padding: 5px;
    width: 20%;
    margin-left: 0%;
    background: #acacac;
    border: 2.5px solid #000000;
    box-shadow: 1px 1px 5px;
     margin-bottom: 0px;
    bottom: 14.5%;
    transition: .2s;
    position: absolute;
  }
  .walletCheck.active {
    height: 17.5px;
    top: 40%;
  }
  
  .walletCheck.deactive {
    display: none;
  }
  
  .walletCheck.active .mid1 {
  }
  
  .walletCheckSec {
    border: none;
    margin-top: 5px;
    height: 55vh;
    background: #6a8171;
    overflow: hidden;
    border: 2.5px solid #939393;
  
  }
  // .Wallet-Connect {
  //     position: fixed;
  //     right: -100%;
  //     top:7%;
  //     transition: .2s
  // }
  .Wallet-Connect {
    background: black;
    border-radius: 16px;
    margin-right: 0%;
    left: 15%;
    top:10%;
    position: relative;
  }

  .Wallet-Connect.active {
      right: 0
  }

  .Wallet-Connect button {
      border: 3px solid #feba00;
      font-size: 20px;
      height: 45px;
      padding-top: 0;
      width: 180px
  }

.Word-Mark {
  width: 35%;
  display: none;
  
  }
  .middle-cont {
    width: 100%;
  }

  .middle-cont .left-bar {
    width: 20%;
    margin-left: 2.5%;
    height: 70%;
    padding-top: 3.5px;
    justify-content: space-around;
  }

  .middle-cont .middle-bar {
    width: 45%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .middle-cont .middle-bar h1{
   font-size: 75%;
   line-height: 100%;
   padding-top: 5px;
   padding-left: 10px;
   padding-right: 5px;
  }

  .middle-cont .right-bar {
    width: 20%;
    margin-right: 2.5%;
    height: 70%;
    padding-top: 3.5px;
    justify-content: space-around;
  }
  .countDown {
    width: 20%;
  }

  .countDown h1 {
    font-size: 80%;
    line-height: 90%;
  }

.nav-pepe {
  display: none;
}

.medianavbar {
  display: flex;
    align-items: center;
    width: 100%;
    max-height: 4.5vh;
    // font-family: 'spooky';
   background: #c1c1c1;
    z-index: 100;
    overflow-x: hidden;
   position: fixed;
   top: 0;
   transition: 1s;
   overflow-y: hidden;
   border:2px solid #000000;
}

.mediaLB {
  width: 25%;
}

.mediaM {
  width: 50% !important;
}

.mediacountDown {
  width: 100%;
  border-left: 2px solid black;
  border-right: 2px solid black;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.mediacountDown h1 {
  font-size: 170%;
  font-weight: 3px;
  font-family: spooky;
}
.mediacountDown h1 span {
  color: #ffff54;
  font-family: spooky;
}

.mediaRB {
  width: 25%;
}

.App {
  height: 100vh;
  overflow: hidden;
}

.Landing {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%; // Adjust this value if you need the height to be different
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: scroll;
}



.mintSection {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #f94700e1;
  align-items: center;
  border: 5px solid rgba(0, 0, 0, 0);
  margin-left: 0%;
  border-radius: 25px;
  height: 440px;
  width: 90%;
  top: 5%;
  bottom: 3%;
  position: relative;
}

.mintSection p {
  font-size: 150%;
  color: white;
  margin-top: 6%;
  font-family: spooky;
  letter-spacing: 5px;
}

.mintSection img {
  height: 200px;
  margin-top: 2.5%;
}

.mintingIncrements {
  margin-top: 5%;
  margin-bottom: 1%;
  width: 280px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mintingIncrements button {
  height: 40px;
  width: 40px;
  font-size: 25px;
  border-radius: 5px;
  border: 2px solid rgb(0, 0, 0);
  color: white;
  background: #f94700e1;
  cursor: pointer;
  transition: 0.2s;

}

.mintingIncrements button:hover {
  background: rgb(0, 0, 0);
}

.mintingIncrements input {
  height: 33px;
  width: 175px;
  color: white;
  border: 2px solid rgb(0, 0, 0);
  background: black;
  text-align: center;
  font-size: 125%;
  border-radius: 5px;
}

#mintbtn {
  margin-bottom: 5%;
  height: 50px;
  width: 280px;
  border: 2px solid rgba(0, 0, 0, 0.553);
  background: #f94700e1;
  color: white;
  font-size: 22.5px;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 5px;
}

#mintbtn:hover {
  background: rgb(0, 0, 0);
}

.info {
  position: relative;
  top: 20%;
  right: 35%;
  text-align: left;
  display: none;
}
.info h1{
  text-align: left;
  font-family: spooky;
  font-size: 300%;
  letter-spacing: 5px;
  color: #ebe6e4;
}
.info h2{
  text-align: left;
  font-family: spooky;
  font-size: 100%;
  line-height: 250%;
  letter-spacing: 5px;
  color: whitesmoke;
}
.info h2 span{
  text-align: left;
  font-family: spooky;
  font-size: 100%;
  line-height: 350%;
  letter-spacing: 5px;
  color: #f94700;
}

// counter
  .counter {
    position: relative;
    width: 90%;
    right: 0;
    bottom: 0;
    margin-top: 12.5%;
    margin-bottom: 5%;
    transition: .2s;
  }

  .counter.active {
  }


  .counterSec.active {
    margin-top: .5px;
  }
  .counter.active .rSide1 {
  }

  .counterSec h2 {
    font-size: 55px !important;
    padding: 0;
    margin: 0;
    line-height: 100%;
    letter-spacing: 2px !important;
    text-align: center;
    margin-top: 2%;
    margin-left: 2% !important;
  }
  .counterSec h3 {
    font-size: 35px !important;
    letter-spacing: 0px;
  }

  .counterSec.active h2 {
    font-size: 0px !important;
  }

  .counterSec.active h3 {
    font-size: 0px !important;
  }

  // allowlist
  .allowList {
    position: relative;
    width: 90%;
    left: 0;
    top: 0;
    margin-bottom: 5%;
  }

  .windowOptions h2 {
    font-size: 13px;
  }

  #SideWL1 {
    font-size: 13px;
  }
  #SideWL2 {
    font-size: 13px;
  }
  
  .inputSec.active {
  }


  .allowInput {
    margin-bottom: 2.5%;
  }

  .allowInput input {
    font-size: 100%;

  }
  .allowInput button {
    font-size: 85%;
  }

  // demboys mint
  .demboyz {
    width: 90%;
    position: relative;
    top: 15%;
    bottom: 0%;
    margin-bottom: 10%;
  }

  .demboyzSec {
    height: 55vh;
  }

  //   walletcheck
  .walletCheck {
    position: relative;
    width: 90%;
    left: 0;
    top: 0;
    margin-bottom: 5%;
  }
  

  // walletcheckerror 

  .walletCheckError {
    width: 96%;
    left: 0%;
  }

  .walletCheckErrorSec {
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .walletCheckErrorSec h2{
    font-size: 28px;
    margin-bottom: 15px;
  }

.walletCheckErrorImgSec {
  margin-top: 15px;
  margin-bottom: 15px;
}


.walletCheckErrorImgSec:nth-child(1) {
  margin-top: 10px;
  margin-bottom: 25px;
}

  .walletCheckErrorImgCont {
    height: 50px;
    width: 50px;
  }

  .walletCheckErrorExp {
    position: absolute;
    font-size: 40px;
    font-family: spooky;
    padding: 0;
    margin: 0;
    letter-spacing: -3px;
    margin-top: 5px;
  }

  .walletCheckErrorSec.flash .errorExp {
    color: #ffff54;
  }

  // error 
  .error {
    width: 96%;
    left: 0%;
  }

  .errorSec {
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .errorSec h2{
    font-size: 28px;
    margin-bottom: 15px;
  }

  .errorImgSec {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin-left: 5%;
  }

  .errorImgCont  {
    height: 50px;
    width: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .errorImgCont img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .errorExp {
    position: absolute;
    font-size: 40px;
    font-family: spooky;
    padding: 0;
    margin: 0;
    letter-spacing: -3px;
    margin-top: 5px;
  }

  .errorSec.flash .errorExp {
    color: #ffff54;
  }

  .errorGap {
    height: 10px;
  }

  // legal {
    .legal {
      width: 90%;
    }
  // milady AL
  .miladyAL {
    position: relative;
    width: 90%;
    left: 0;
    top: 0;
    margin-bottom: 5%;
    margin-right: 0;
    margin-left: .25%;
  }
  .miladyAL.active {
  }
  .miladyALInputSec.active {
  }

  .miladyAL input {
    font-size: 100%;
  }
  .miladyAL button {
    font-size: 85%;
  }

  // pepe AL
  .pepeAL {
    position: relative;
    width: 90%;
    top: 0;
    left: 0;
    margin-bottom: 5%;
    margin-right: 0;
    margin-left: .25%;

  }
  .pepeAL.active {
  }
  .pepeALInputSec.active {
  }
  .pepeAL input {
    font-size: 100%;
  }
  .pepeAL button {
    font-size: 85%;
  }
}
/******{nav animations }**********************************************************************/
@keyframes navLinkFade {
      from{
          opacity: 0;
          transform: translateX(50px);
      }
      to{
          opacity: 1;
          transform: translateX(0px);

      }
}

.toggle .line1{    
  transform: rotate(-45deg) translate(-5px, 6px);

}
.toggle .line2{    
  opacity: 0;
}
.toggle .line3{    
  transform: rotate(45deg) translate(-5px, -6px);
}


/******{ Nav }*******************************************************************************************************************************/

@font-face {
  font-family: spooky;
  src: url(./components/assets/fonts/LoRes12OTNarrow-Regular.ttf);
}